.form__field {
    margin-bottom: 16px;
}

.form__buttons {
    margin-top: 24px;
}

.form__button--add {
    margin-left: 8px;
}
