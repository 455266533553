.app-container {
  min-height: 100vh !important;
  padding-left: 250px; }

.content-container {
  margin: 24px 16px;
  padding: 24px;
  background: #ffffff;
  min-height: 280px; }

.footer-container {
  text-align: center; }

.header-container {
  padding-right: 16px !important; }

.sidebar-container {
  width: 250px;
  top: 0;
  left: 0;
  bottom: 0;
  position: fixed !important; }

.logo-container {
  text-align: center;
  padding-top: 10px; }
  .logo-container h1 {
    color: white;
    font-size: 1.8em; }

.user-menu-container {
  max-width: 200px;
  float: right;
  width: auto !important;
  z-index: 1;
  position: relative; }
  .user-menu-container li.ant-menu-item {
    padding: 0px 16px !important; }

.text-danger {
  color: #ff4d4f !important; }

a.text-danger:hover,
button.text-danger:hover,
button.text-danger:focus,
button.text-danger:active {
  color: #e60003 !important; }

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.c-pointer {
  cursor: pointer; }
